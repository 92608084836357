<script>
import { eventBus } from "@/main";
export default {
  data() {
    return {
      listaDeVacantes: [],
      filtroCargoEmpresa: "",
      filtroUbicacion: "",
      vistaActiva: "lista",
      esPantallaGrande: window.innerWidth >= 992,
    };
  },
  async mounted() {
    this.$isLoading(true);
    await this.getListaDeVacantes();
    this.$isLoading(false);
    eventBus.$on("volverALista", this.volverALista);
    window.addEventListener("resize", this.detectarTamanoPantalla);
  },
  beforeDestroy() {
    eventBus.$off("volverALista", this.volverALista);
    // Limpia el listener al destruir el componente
    window.removeEventListener("resize", this.detectarTamanoPantalla);
  },
  methods: {
    detectarTamanoPantalla() {
      this.esPantallaGrande = window.innerWidth >= 992;
    },
    mostrarDetalleVacante() {
      this.vistaActiva = "detalle";
    },
    volverALista() {
      this.vistaActiva = "lista";
    },
    async getListaDeVacantes() {
      const response = await this.$store.getters.fetchGet({
        path: `Vacante/all/active`,
      });
      this.listaDeVacantes = await response.json();
      // this.$router.push({
      //   name: "InformacionVacante",
      //   params: { id: this.listaDeVacantes[0].id },
      // });
    },
    calcularDiferenciaDeTiempo(fecha) {
      const fechaObj = new Date(fecha);
      const ahora = new Date();
      const diferenciaEnMilisegundos = ahora - fechaObj;

      const minutos = Math.floor(diferenciaEnMilisegundos / (1000 * 60));
      const horas = Math.floor(minutos / 60);
      const dias = Math.floor(horas / 24);
      const semanas = Math.floor(dias / 7);
      const meses = Math.floor(dias / 30);
      const años = Math.floor(meses / 12);

      if (años > 0) {
        return `Hace ${años} ${años === 1 ? "año" : "años"} `;
      } else if (meses > 0) {
        return `Hace ${meses} ${meses === 1 ? "mes" : "meses"}`;
      } else if (semanas > 0) {
        return `Hace ${semanas} ${semanas === 1 ? "semana" : "semanas"}`;
      } else if (dias > 0) {
        return `Hace ${dias} ${dias === 1 ? "día" : "días"}`;
      } else if (horas > 0) {
        return `Hace ${horas} ${horas === 1 ? "hora" : "horas"}`;
      } else {
        return `Hace ${minutos} ${minutos === 1 ? "minuto" : "minutos"}`;
      }
    },
  },
  computed: {
    activeUser() {
      const user = localStorage.getItem("setPersonaAct");
      console.log("user", user);
      if (user) {
        return JSON.parse(user);
      }
      return null;
    },
    filtradoDeVacantes() {
      return this.listaDeVacantes.filter((vacante) => {
        return (
          (vacante.cargo.nombre
            .toLowerCase()
            .includes(this.filtroCargoEmpresa.toLowerCase()) ||
            vacante.cargo.sucursal.empresa.nombre
              .toLowerCase()
              .includes(this.filtroCargoEmpresa.toLowerCase())) &&
          (vacante.pais.descripcion
            .toLowerCase()
            .includes(this.filtroUbicacion.toLowerCase()) ||
            vacante.municipio.descripcion
              .toLowerCase()
              .includes(this.filtroUbicacion.toLowerCase()))
        );
      });
    },
  },
};
</script>

<template>
  <HXContentCard
    title="Listado de vacantes"
    :toBack="activeUser && { name: 'AdministradorDeVacantes' }"
  >
    <div class="row p-3">
      <!-- content vacantes -->
      <div
        v-if="vistaActiva === 'lista' || esPantallaGrande"
        class="col-12 col-lg-5"
      >
        <!-- filtros -->
        <div class="py-3 pl-3">
          <strong>Filtrar por:</strong>
          <div class="d-flex mt-1">
            <b-form-input
              v-model="filtroCargoEmpresa"
              type="text"
              class="mr-2 mb-2"
              placeholder="Cargo o empresa..."
            >
            </b-form-input>

            <b-form-input
              v-model="filtroUbicacion"
              type="text"
              placeholder="Ciudad, provincia o estado..."
            >
            </b-form-input>
          </div>
        </div>
        <!-- vacantes -->
        <div class="overflow-75">
          <router-link
            v-for="vacante in filtradoDeVacantes"
            :key="vacante.id"
            @click.native.prevent="mostrarDetalleVacante()"
            :to="{ name: 'InformacionVacante', params: { id: vacante.id } }"
            class="d-flex m-0 p-3 card-vacante pointer"
            :class="{ active: $route.params.id == vacante.id }"
          >
            <figure>
              <img
                v-if="vacante.cargo.sucursal.empresa.urlLogo"
                :src="vacante.cargo.sucursal.empresa.urlLogo"
                alt="logo-empresa"
                width="100"
              />
              <div
                v-else
                style="width: 81.42px"
                class="d-flex justify-content-center"
              >
                <i class="fas fa-building fa-4x text-white-50"></i>
              </div>
            </figure>
            <div class="content">
              <h3>
                <strong>
                  {{ vacante.cargo.nombre }}
                </strong>
              </h3>
              <p>
                <strong>
                  {{ vacante.cargo.sucursal.empresa.nombre }}
                </strong>
              </p>
              <p>
                {{ vacante.pais.descripcion }},
                {{ vacante.municipio.descripcion }}, ( Presencial,
                {{ vacante.jornadaLaboral.name }} )
              </p>
              <p></p>
              <p>{{ vacante.salario }} / Mes</p>
              <div class="d-flex justify-content-end">
                <p>{{ calcularDiferenciaDeTiempo(vacante.fechaDeCreacion) }}</p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <!-- content info vacante -->
      <div
        v-if="vistaActiva === 'detalle' || esPantallaGrande"
        class="col-12 col-lg overflow-75"
      >
        <button
          v-if="!esPantallaGrande && vistaActiva === 'detalle'"
          @click="volverALista"
          class="btn btn-dark my-3"
        >
          <i class="fas fa-arrow-left"></i>
          Volver a la lista
        </button>
        <router-view></router-view>
      </div>
    </div>
  </HXContentCard>
</template>

<style scoped>
p {
  margin: 0 0 0.5rem 0;
}

.overflow-75 {
  overflow-y: auto;
  height: 75vh;
}

.card-vacante:hover {
  background-color: var(--gray-100);
}
.card-vacante.active {
  background-color: var(--gray-300);
}
.card-vacante .content {
  width: 100%;
  padding: 0 0.8rem;
}

@media (max-width: 768px) {
  .card-vacantes {
    min-width: 100%;
  }

  .d-flex {
    flex-direction: column;
  }

  .card-vacante {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .card-vacante .content {
    padding: 0;
  }

  .overflow-75 {
    height: auto;
    max-height: 60vh;
  }
}
</style>
